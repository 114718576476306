const makeDiff = async (ontUrl) => {
  const queryString = new URLSearchParams({ uri: ontUrl }).toString();
  const response = await fetch(
    `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/ondet/sdiffs?${queryString}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );

  if (response.ok) {
    return await response.text();
  }

  const errorData = await response.json();
  throw new Error(errorData.message || "Failed to create ontology diff.");
};

const makeDiffBatch = async (urls) => {
  const queryString = new URLSearchParams();
  urls.forEach(url => queryString.append('uris', url));
  const response = await fetch(
    `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/ondet/sdiffs/createBatch?${queryString}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );

  if (response.ok) {
    return await response.text();
  }

  const errorData = await response.json();
  throw new Error(errorData.message || "Failed to create ontology diff.");
};

export const ondetService = {
  ondetDiff: async (data) => {
    return await makeDiff(data.url);
  },
  makeDiffBatch: async (data) => {
    return await makeDiffBatch(data);
  },
};
