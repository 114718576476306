import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  maxWidth: '800px',
  margin: 'auto',
});

const AssessmentElements = styled('div')({
  margin: '10px 0',
  padding: '10px',
  backgroundColor: '#fff3cd',
  borderLeft: '5px solid #ffc107',
  borderRadius: '4px',
  lineHeight: '1.5',
});


export const QualityAssessmentExplanation = () => {
  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Quality Assessment Overview
      </Typography>

      <Typography variant="body1" paragraph>
        The Quality Assessment of Git repositories is calculated based on several factors related to the repository's
        community engagement and the presence of essential elements. The score is designed to give an overview of the
        repository's quality by evaluating various aspects like the number of forks, stars, watchers, releases, ReadMe file, 
        and license availability.
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Community Assessment Overall Measure
      </Typography>
      <Typography variant="body1" >
        This score is calculated based on the following metrics:
      </Typography>
      <AssessmentElements>
      <List>
        <ListItem>
          <ListItemText
            primary="Forks"
            secondary="The number of times the repository has been forked by other users. More forks usually indicate higher engagement and interest."
            />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Watchers"
            secondary="The number of users who are watching the repository for updates. A higher number of watchers shows that the project is being followed closely by the community."
            />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Stars"
            secondary="The number of stars given by users to the repository. This is often considered a measure of the repository's popularity and quality."
            />
        </ListItem>
      </List>
      </AssessmentElements>
      <Typography variant="body1" paragraph>
        These metrics are normalized to a scale of 0 to 100, with the maximum value among all repositories used as a benchmark.
        Each metric (forks, watchers, stars) is assigned a one third of percentage score based on how it compares to the maximum value
        in the entire set of repositories.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        2. Data Assessment Overall Measure
      </Typography>
      <Typography variant="body1" >
        This score evaluates the presence of the following important repository elements:
      </Typography>
      <AssessmentElements>

      <List>
        <ListItem>
          <ListItemText
            primary="Releases"
            secondary="Indicates whether the repository has release versions available. A release is important for users to get stable versions of the project."
            />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ReadMe"
            secondary="Having a ReadMe file with project information and instructions for use is essential for helping users understand the repository's purpose."
            />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="License"
            secondary="A valid license is important for specifying how the project can be used and redistributed by others."
            />
        </ListItem>
      </List>
            </AssessmentElements>

      <Typography variant="body1" paragraph>
        Each element existence means a score of one third of the total percentage. if the repository has all the required elements (releases, ReadMe, and license), it will receive a higher score.
      </Typography>
    </Container>
  );
};
