import React, { useEffect, useState } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DiffTable } from "../DiffTable";
import { OndetTable } from "./OndetTable";

export const ContoManagement = ({ ontology }) => {
  const [ontologies, setOntologies] = useState([]);
  const [ontologiesLoaded, setOntologiesLoaded] = useState(false);

  const fetchOntologies = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/ondet/sdiffs`,
      );
      console.log("ontology: ", ontology);
      const data = await response.json();
      if (ontology !== undefined) {
        setOntologies([{ name: ontology }]);
      } else {
        setOntologies(data);
      }
      setOntologiesLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOntologies();
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={11}>
        <div className="target-element3">
          <Typography variant="h5" gutterBottom textAlign="center">
            Ontology Development Tracker (OnDeT)
          </Typography>
          <Typography>
            Small changes to an ontology can have a major impact on its
            validity. These changes can be syntactic (e.g. grammatical errors in
            an rdfs:comment) or semantic (e.g. adding, editing or removing
            concepts). Both types of changes are difficult to recognise. It
            becomes even more complex when a specific change needs to be found.
            Especially if it is not known from the outset in which version it
            was made.
          </Typography>
          <br />
          <Typography>
            If you want to add further ontologies, please go to{" "}
            <Link
              component={RouterLink}
              to={process.env.REACT_APP_PROJECT_SUB_PATH + "/sandbox/ondet/add"}
            >
              Track changes
            </Link>
          </Typography>
          <br />
          {ontologiesLoaded ? (
            <OndetTable
              ontologies={ontologies}
              apiUrl={`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/ondet/sdiffs`}
              onUpdated={fetchOntologies}
              onDeleted={fetchOntologies}
              onSaved={fetchOntologies}
            />
          ) : (
            <p>Loading ontologies...</p>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
