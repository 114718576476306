import { useState, useEffect } from 'react';
import Footer from './components/common/Footer/Footer';
import Header from './components/common/Header/Header';
import { MatomoWrapper } from './components/Matomo/MatomoWrapper';
import CookieBanner from './components/common/CookieBanner/CookieBanner';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { BackendIsDownMessage, setSiteTitleAndFavIcon, InlineWrapperWithMargin } from './AppHelpers';
import Auth from './Libs/AuthLib';
import AppRouter from './Router';
import { LoginLoadingAnimation } from './components/User/Login/LoginLoading';
import { AppContext } from './context/AppContext';
import { getReportList } from './api/tsMicroBackendCalls';
import LoadingPage from './LoadingPage';
import { olsIsUp } from './api/system';
import { useQuery } from '@tanstack/react-query';
import './components/layout/common.css';
import './components/layout/mediaQueries.css';
import './components/layout/custom.css';
import { useLocation } from 'react-router-dom';

// Sandbox related imports
import { SandboxFooter } from './components/sandbox/common/SandboxFooter';

export const SandboxApp = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [isBackendDown, setIsBackendDown] = useState(false);
  const [reportsListForAdmin, setReportsListForAdmin] = useState([]);
  const [userSettings, setUserSettings] = useState({
    activeCollection: { title: '', ontology_ids: [] },
    userCollectionEnabled: false,
    advancedSearchEnabled: false,
    activeSearchSetting: {},
    activeSearchSettingIsModified: false,
  });

  const [showLoadingPage, setShowLoadingPage] = useState(true);

  const location = useLocation();

  // Check if the current path starts with /sandbox
  const isSandboxRoute = location.pathname.startsWith(process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox');

  const olsIsUpQuery = useQuery({ queryKey: ['olsIsUpCall'], queryFn: olsIsUp, meta: { cache: false } });
  if (olsIsUpQuery.isError) {
    setIsBackendDown(true);
  }

  useEffect(() => {
    setSiteTitleAndFavIcon();

    if (process.env.REACT_APP_AUTH_FEATURE === 'true') {
      let cUrl = window.location.href;
      if (cUrl.includes('code=')) {
        Auth.run();
      }

      Auth.userIsLogin().then((user) => {
        setUser(user);
        setIsSystemAdmin(user?.systemAdmin);
        let settings = { ...userSettings };
        settings.userCollectionEnabled = user?.settings?.userCollectionEnabled;
        settings.advancedSearchEnabled = user?.settings?.advancedSearchEnabled;
        settings.activeSearchSettingIsModified = user?.settings?.activeSearchSettingIsModified;
        if (user?.settings?.activeCollection?.title) {
          settings.activeCollection = user?.settings?.activeCollection;
        }
        if (user?.settings?.activeSearchSetting) {
          settings.activeSearchSetting = user?.settings?.activeSearchSetting;
        }
        setUserSettings(settings);
        setShowLoadingPage(false);
      });

      getReportList().then((reports) => {
        setReportsListForAdmin(reports);
      });
    } else {
      setShowLoadingPage(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const appContextData = {
    user: user,
    isUserSystemAdmin: isSystemAdmin,
    reportsListForAdmin: reportsListForAdmin,
    userSettings: userSettings,
    setUserSettings: setUserSettings,
  };

  return (
    <div className="App">
      <LoginLoadingAnimation />
      <MatomoWrapper>
        <div className={!isSandboxRoute ? 'container-fluid' : ''} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppContext.Provider value={appContextData}>
            {showLoadingPage && <LoadingPage />}
            {!showLoadingPage && (
              <>
                <Header />
                {process.env.REACT_APP_PROJECT_ID === "nfdi4ing" &&
                    <div className='row alert alert-warning text-center' id="site-warning-banner" style={{marginBottom: 0}}>
                      <div className='col-sm-12'>
                        The NFDI4ING Terminology Service is now running under OLS4.
                        You may experience missing ontologies for a short time during the transition phase.
                      </div>
                    </div>
                  }

                <div style={{ flexGrow: 1 }}>
                  <div className={!isSandboxRoute ? 'application-content' : ''} id="application_content">
                    {loading && <Skeleton count={3} wrapper={InlineWrapperWithMargin} inline width={600} height={200} marginLeft={20} baseColor={'#f4f2f2'} />}
                    {!loading && (
                      <>
                        {isBackendDown && <BackendIsDownMessage />}
                        <CookieBanner />
                        <AppRouter />
                      </>
                    )}
                  </div>
                </div>
                <SandboxFooter />
              </>
            )}
          </AppContext.Provider>
        </div>
      </MatomoWrapper>
    </div>
  );
};
