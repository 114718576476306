import { QualityAssessment } from './QualityAssessment';
import { CircularProgress } from '@mui/material';
import { GetQualityAssessment } from '../../../api/queryData';

const QualityAssessmentData = () => {
  const { isLoading, isError, error, data } = GetQualityAssessment(); 
  console.log(data)

  return (
    <div>
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : isError ? (
        <div>Error: {error.message}</div>
      ) : (
        <QualityAssessment qualityAssessmentData={data} />
      )}
    </div>
  );
};

export default QualityAssessmentData;
