import { useState } from 'react';
import { Tab, Tabs, LinearProgress, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { a11yProps, TabPanel } from '../theme/Theme';
import { DataAssessment } from './DataAssessment';
import { CommunityAssessment } from './CommunityAssessment';
import { CheckCircle, Cancel, HelpOutline,  } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    minHeight: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    fontWeight: 'bolder',
    backgroundColor: 'none',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    transform: 'translate(0px, 1px)',
    '& .Mui-selected': {
      borderRight: '1px solid',
      borderTop: '1px solid',
      borderLeft: '1px solid',
      borderRightColor: theme.palette.grey[400],
      borderLeftColor: theme.palette.grey[400],
      borderTopColor: theme.palette.grey[400],
    },
  },
  tabpanel: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    minHeight: '70vh',
    padding: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: ' 0 0px 10px 10px',
  },
  progressBar: {
    width: '100%',
    '& .MuiLinearProgress-root': {
      height: '20px',
      color: theme.palette.primary.light,
    },
  },
  clickableTitle: {
    fontSize: '15px',
    color: '#4f6d7a',
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline', 
    },
  },
}));


export const QualityAssessment = ({ qualityAssessmentData }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);


  const RenderProgress = ({ percentage }) => (
      <div className={classes.progressBar}>
        <LinearProgress variant="determinate" value={percentage} color="primary" />
      </div>
  );

  const getDataAssessmentRows = () => {
    return qualityAssessmentData.map(item => {
      return {
        ontologyId: item.ontologyId,
        title: item.title,
        repoUrl: item.repoUrl,
        hasLicense: item.hasLicense,
        hasReadMe: item.hasReadMe,
        hasReleases: item.hasReleases,
        dataAssessmentScore: item.dataAssessmentScore
      }
    })
  };

  const getCommunityAssessmentRows = () => {
    return qualityAssessmentData.map(item => {
      return {
        ontologyId: item.ontologyId,
        title: item.title,
        repoUrl: item.repoUrl,
        forks: item.forks,
        stars: item.stars,
        watchers: item.watchers,
        communityAssessmentScore: item.communityAssessmentScore
      }
    })
  };

  const handleChange = (event, newValue) => setValue(newValue);

  const createColumnHeader = (headerText, tooltipText) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>{headerText}</span>
      <Tooltip title={tooltipText} placement="top" >
        <HelpOutline sx={{ fontSize: 16, marginLeft: 3, cursor: 'pointer' }} />
      </Tooltip>
    </div>
  );

  const dataAssessmentColumns = [
    {
      accessorKey: 'title',
      header: 'Ontology',
      Header: () => createColumnHeader('Ontology', 'Name of the ontology which has a git repository available'),
      size: 250,
      Cell: ({ cell, row }) => (
        <div
        className={classes.clickableTitle}
          onClick={() => window.open(row.original.repoUrl, '_blank')}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: 'logo',
      header: 'Platform',
      Header: () => createColumnHeader('Platform', 'Git platform where the repository is hosted'),
      size: 100,
      Cell: ({ row }) => {
        const isGitHub = row.original.repoUrl.startsWith('https://github.com');
        const logoSrc = isGitHub
          ? 'https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png'
          : 'https://about.gitlab.com/images/press/logo/svg/gitlab-logo-gray-rgb.svg';
        return (
          <img
            src={logoSrc}
            alt={isGitHub ? 'GitHub' : 'GitLab'}
            style={{ height: '24px', width: '24px' }}
          />
        );
      },
    },
    {
      accessorKey: 'hasLicense',
      header: 'License',
      Header: () => createColumnHeader('License', 'Indicates if the git repository has an open source license available'),
      size: 100,
      Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? <CheckCircle sx={{ color: '#4f6d7a' }} /> : <Cancel sx={{ color: 'red' }} />}
          </div>
      ),
    },
    {
      accessorKey: 'hasReadMe',
      header: 'ReadMe',
      Header: () => createColumnHeader('ReadMe', 'Indicates if the git repository has a README file'),
      size: 100,
      Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? <CheckCircle sx={{ color: '#4f6d7a' }} /> : <Cancel sx={{ color: 'red' }} />}
          </div>
      ),
    },
    {
      accessorKey: 'hasReleases',
      header: 'Release',
      Header: () => createColumnHeader('Release', 'Indicates if the git repository has releases'),
      size: 100,
      Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? <CheckCircle sx={{ color: '#4f6d7a' }} /> : <Cancel sx={{ color: 'red' }} />}
          </div>
      ),
    },
    {
      accessorKey: 'dataAssessmentScore',
      header: 'Data Assessment Score',
      Header: () => createColumnHeader('Data Assessment Score', 'Score based on data assessment criteria. Each element existence means a score of one third of the total percentage. if the repository has all the required elements (releases, ReadMe, and license), it will receive a higher score.'),
      Cell: ({ cell }) => <RenderProgress percentage={cell.getValue()} />,
      align: 'center',
    },
  ];

  const communityAssessmentColumns = [
    {
      accessorKey: 'title',
      header: 'Ontology',
      Header: () => createColumnHeader('Ontology', 'Name of the ontology being assessed'),
      size: 250,
      Cell: ({ cell, row }) => (
        <div
        className={classes.clickableTitle}
          onClick={() => window.open(row.original.repoUrl, '_blank')}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: 'logo',
      header: 'Platform',
      Header: () => createColumnHeader('Platform', 'Platform where the repository is hosted'),
      size: 100,
      Cell: ({ row }) => {
        const isGitHub = row.original.repoUrl.startsWith('https://github.com');
        const logoSrc = isGitHub
          ? 'https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png'
          : 'https://about.gitlab.com/images/press/logo/svg/gitlab-logo-gray-rgb.svg';
        return (
          <img
            src={logoSrc}
            alt={isGitHub ? 'GitHub' : 'GitLab'}
            style={{ height: '24px', width: '24px' }}
          />
        );
      },
    },
    {
      accessorKey: 'forks',
      header: 'Forks',
      Header: () => createColumnHeader('Forks', 'Number of forks of the repository'),
      size: 100,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'stars',
      header: 'Stars',
      Header: () => createColumnHeader('Stars', 'Number of stars the repository has received'),
      size: 100,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'watchers',
      header: 'Watchers',
      Header: () => createColumnHeader('Watchers', 'Number of watchers of the repository'),
      size: 100,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'communityAssessmentScore',
      header: 'Community Assessment Score',
      Header: () => createColumnHeader('Community Assessment Score', 'This score is calculated based on Forks, Watchers and Stars. These metrics are normalized to a scale of 0 to 100, with the maximum value among all repositories used as a benchmark. Each metric (forks, watchers, stars) is assigned a one third of percentage score based on how it compares to the maximum value in the entire set of repositories.'),
      Cell: ({ cell }) => <RenderProgress percentage={cell.getValue()} />,
    },
  ];

  return (
      <div className={classes.root}>
        <Tabs
            onChange={handleChange}
            value={value}
            className={classes.tabs}
            TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab label="Data Assessment" className={classes.tab} {...a11yProps(0)} />
          <Tab label="Community Assessment" className={classes.tab} {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabpanel}>
          <DataAssessment dataAssessmentRows={getDataAssessmentRows()} dataAssessmentColumns={dataAssessmentColumns} />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabpanel}>
          <CommunityAssessment communityAssessmentRows={getCommunityAssessmentRows()} communityAssessmentColumns={communityAssessmentColumns} />
        </TabPanel>
      </div>
  );
};